import { Button, Spinner } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { userRole } from "../../../constant";
import { Select } from "antd";
import { validateFields } from "../../../utils/validation";
import { useSelector } from "react-redux";
import { useSearchQuery } from "../../../hooks/useSearchQuery";

const formSchema = {
  name: {
    type: "string",
    required: true,
  },
  email: {
    type: "string",
    required: true,
  },

  phone: {
    type: "string",
    required: true,
  },
  fax: {
    type: "string",
    required: false,
  },
  role: {
    type: "string",
    required: true,
  },
};

const getFormSchema = (mode, role) => {
  let schema = {};
  if (mode === "create") {
    schema = {
      ...formSchema,
      password: {
        type: "string",
        required: true,
      },
      cpassword: {
        type: "string",
        required: true,
        match: "password",
        message: "Confirm Password did not match",
      },
    };
  }
  if (["user", "public", "admin"].includes(role)) {
    schema = {
      ...formSchema,
      site: { type: "array", minLength: 1 },
    };
  } else if (["admin", "installer"].includes(role)) {
    schema = {
      ...formSchema,
      companyName: { type: "string", required: true },
      companyAddress: { type: "string", required: true },
    };
  }
  return schema;
};

/**
 *
 * @param {{ mode: string, user: User }} param0
 * @returns
 */

const UserForm = ({ mode = "create", user, onClickChangePwd }) => {
  const navigate = useNavigate();
  const role = useSearchQuery("role");

  const loggedInUser = useSelector((state) => state?.user?.userDetails);

  const [error, setError] = useState({});
  const [SuccessMessage, setSuccessMessage] = useState();
  const [ErrorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [siteLocations, setSiteLocations] = useState([]);

  console.log({ role });
  const [state, setState] = useState({
    name: "",
    email: "",
    password: "",
    cpassword: "",
    phone: "",
    fax: "",
    role: role || "public",
    site: [],
    companyName: "",
    companyAddress: "",
  });

  const getSiteLocations = async () => {
    try {
      const { data } = await axios.get(`/site-location/my-site`, { withCredentials: true });
      if (data) {
        setSiteLocations(data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (["user", "public", "admin", "installer"].includes(state.role)) {
      getSiteLocations();
      Object.assign(formSchema, { site: { type: "array", minLength: 1 } });
    } else {
      setState({ ...state, site: [] });
      delete formSchema.site;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.role]);

  useEffect(() => {
    if (mode === "edit" && user) {
      setState({
        name: user.name,
        email: user.email,
        phone: user.phone,
        role: user.role,
        site: user.site.map((s) => (typeof s === "string" ? s : s._id)),
        companyName: user.companyName,
        companyAddress: user.companyAddress,
      });
    }
  }, [mode, user]);

  const onInputChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: "" });
  };

  const onSelectSite = (value) => {
    setState({ ...state, site: value });
    setError({ ...error, site: "" });
  };

  //Profile Photo
  const handleFileUpload = (file) => {
    setImageUrl(URL.createObjectURL(file));
    let form = new FormData();
    form.append("avatar", file);
    setSelectedImage(form);
  };

  const createUser = async () => {
    const { values, errors } = validateFields(state, getFormSchema(role, mode));
    console.log({ values, errors });

    if (errors) {
      return setError(errors);
    }

    if (state.password === state.cpassword) {
      setIsLoading(true);
      try {
        const response = await axios.post(`/user`, values, {
          withCredentials: true,
        });
        const data = response.data;
        if (data) {
          if (selectedImage === null) {
            setIsLoading(false);
            setSuccessMessage("User Create Successfully");
            setTimeout(() => {
              setSuccessMessage();
              navigate(-1);
            }, 2000);
          } else {
            const addImageResponse = await axios.put(`/user/${data._id}/avatarUpload/`, selectedImage, {
              withCredentials: true,
            });
            if (addImageResponse) {
              setIsLoading(false);
              // setSuccessMessage("User Updated Successfully");
              setTimeout(() => {
                setSuccessMessage();
                navigate(-1);
              }, 2000);
            }
          }
        }
      } catch (error) {
        if (error.response) {
          setErrorMessage(error.response.data?.message || "Something went wrong.");
          setTimeout(() => {
            setErrorMessage();
          }, 2000);
        }
      }
    } else {
      setErrorMessage("Both Passwords Are Not Matching");
      setTimeout(() => {
        setErrorMessage();
      }, 2000);
    }
  };
  const editUser = async () => {
    const { values, errors } = validateFields(state, getFormSchema(role, mode));

    if (errors) {
      return setError(errors);
    }

    setIsLoading(true);
    try {
      const response = await axios.put(`/user/${user._id}`, values, {
        withCredentials: true,
      });
      const data = response.data;
      if (data) {
        if (selectedImage === null) {
          setIsLoading(false);
          setSuccessMessage("User Updated Successfully");
          setTimeout(() => {
            setSuccessMessage();
            navigate(-1);
          }, 2000);
        } else {
          const addImageResponse = await axios.put(`/user/${data._id}/avatarUpload/`, selectedImage, {
            withCredentials: true,
          });
          if (addImageResponse) {
            setIsLoading(false);
            // setSuccessMessage("User Updated Successfully");
            setTimeout(() => {
              setSuccessMessage();
              navigate(-1);
            }, 2000);
          }
        }
      }
    } catch (error) {
      if (error.response) {
        setErrorMessage(error.response.data?.message || "Something went wrong.");
        setTimeout(() => {
          setErrorMessage();
        }, 2000);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (mode === "create") {
      await createUser();
    } else if (mode === "edit") {
      await editUser();
    }
    setIsLoading(false);
  };

  const getHeader = (role) => {
    switch (role) {
      case "installer":
        return {
          title: mode === "edit" ? "Edit Installer" : "Add New Installer",
        };
      case "admin":
        return {
          title: mode === "edit" ? "Edit Admin" : "Add New Admin",
        };
      case "user":
        return {
          title: mode === "edit" ? "Edit Site User" : "Add New Site User",
        };
      case "public":
        return {
          title: mode === "edit" ? "Edit Public User" : "Add New Public User",
        };
      default:
        return {
          title: mode === "edit" ? "Edit User" : "Add New User",
        };
    }
  };

  return (
    <div className="card p-3">
      <h3 className="mb-4">{getHeader(state.role).title}</h3>
      <div className="d-flex justify-content-center">{isLoading && <Spinner animation="border" variant="dark" />}</div>
      {SuccessMessage && (
        <div className="alert alert-success" role="alert">
          {SuccessMessage}{" "}
        </div>
      )}
      {ErrorMessage && (
        <div className="alert alert-danger" role="alert">
          {ErrorMessage}{" "}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-3  mb-3">
            <label htmlFor="role" className="form-label">
              User Role
              <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                *
              </span>
            </label>

            <select
              disabled={!!role}
              className="form-select text-capitalize"
              value={state.role}
              name="role"
              onChange={onInputChange}
              id="role"
              required
            >
              {Object.values(userRole).map(
                (r) =>
                  r !== userRole.superAdmin && (
                    <option key={r} value={r}>
                      {r}
                    </option>
                  )
              )}
            </select>
            {error.role && (
              <div id="" className="text-danger">
                {error.role}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div
            className={`${
              ["user", "public", "admin", "installer"].includes(state.role) ? "col-md-4 " : "col-md-6"
            } mb-3`}
          >
            <label htmlFor="name" className="form-label">
              Full Name
              <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                *
              </span>
            </label>
            <input
              type="text"
              name="name"
              value={state.name}
              onChange={onInputChange}
              className="form-control"
              id="name"
              placeholder="Enter full name"
              required
            />
            {error.name && (
              <div id="" className="text-danger">
                {error.name}
              </div>
            )}
          </div>

          {["user", "public", "admin", "installer"].includes(state.role) && (
            <div className="col-md-4  mb-3">
              <label htmlFor="email" className="form-label">
                Assign Site
                <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                  *
                </span>
              </label>
              <Select
                style={{ width: "100%" }}
                size="large"
                mode="multiple"
                id="site"
                name="site"
                value={state.site}
                onChange={onSelectSite}
                placeholder="Select site"
                options={[...siteLocations.map((item) => ({ label: item.name, value: item._id }))]}
                required
              />
              {error.site && (
                <div id="" className="text-danger">
                  {error.site}
                </div>
              )}
            </div>
          )}

          <div
            className={`${
              ["user", "public", "admin", "installer"].includes(state.role) ? "col-md-4 " : "col-md-6"
            } mb-3`}
          >
            <label htmlFor="email" className="form-label">
              Email Address
              <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                *
              </span>
            </label>
            <input
              type="email"
              name="email"
              value={state.email}
              onChange={onInputChange}
              className="form-control"
              id="email"
              placeholder="Enter email address"
              required
            />
            {error.email && (
              <div id="" className="text-danger">
                {error.email}
              </div>
            )}
          </div>
        </div>

        {mode === "create" && (
          <div className="row">
            <div className="col-md-6 mb-3">
              <label htmlFor="password" className="form-label">
                Password
                <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                  *
                </span>
              </label>
              <input
                type="password"
                name="password"
                value={state.password}
                minLength="6"
                onChange={onInputChange}
                className="form-control"
                id="password"
                placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                required
              />
              {error.password && (
                <div id="" className="text-danger">
                  {error.password}
                </div>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="cpassword" className="form-label">
                Reenter Password
                <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                  *
                </span>
              </label>
              <input
                type="password"
                name="cpassword"
                value={state.cpassword}
                minLength="6"
                onChange={onInputChange}
                className="form-control"
                id="cpassword"
                placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                required
              />
              {error.cpassword && (
                <div id="" className="text-danger">
                  {error.cpassword}
                </div>
              )}
            </div>
          </div>
        )}

        <div className="row ">
          <div className="col-md-6 mb-3">
            <label htmlFor="cname" className="form-label">
              Company Name
              <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                *
              </span>
            </label>
            <input
              type="text"
              name="companyName"
              value={state.companyName}
              onChange={onInputChange}
              className="form-control"
              id="cname"
              placeholder="Enter company name"
              required
            />
            {error.companyName && (
              <div id="" className="text-danger">
                {error.companyName}
              </div>
            )}
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="companyAddress" className="form-label">
              Company Address
              <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                *
              </span>
            </label>
            <input
              type="text"
              name="companyAddress"
              value={state.companyAddress}
              onChange={onInputChange}
              className="form-control"
              id="companyAddress"
              placeholder="Enter company address"
              required
            />
            {error.companyAddress && (
              <div id="" className="text-danger">
                {error.companyAddress}
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="phone" className="form-label">
              Phone Number
              <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                *
              </span>
            </label>
            <div className="input-group">
              <span className="input-group-text" id="basic-addon1">
                +6
              </span>
              <input
                type="number"
                name="phone"
                value={state.phone}
                onChange={onInputChange}
                className="form-control"
                id="phone"
                placeholder="Enter phone number"
                required
              />
            </div>
            {error.phone && (
              <div id="" className="text-danger">
                {error.phone}
              </div>
            )}
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="bname" className="form-label">
            Profile Photo
          </label>

          <div className="input-group m-0">
            <input
              className="form-control"
              accept="image/*"
              type="file"
              id="select-image"
              name="image"
              style={{ display: "none" }}
              onChange={(e) => handleFileUpload(e.target.files[0])}
            />
            <label htmlFor="select-image">
              <img src={imageUrl || "/images/avatar.png"} alt="" height="100px" className="rounded-3 border p-2" />
            </label>
          </div>
        </div>

        <div className="float-end">
          <button type="submit" className="btn btn-success me-2">
            {mode === "edit" ? "Update" : "Create User"}
          </button>
          {loggedInUser?.role === "superAdmin" && mode === "edit" && (
            <button type="button" className="btn btn-info me-2" onClick={() => onClickChangePwd(true)}>
              Change Password
            </button>
          )}
          <Button onClick={() => navigate(-1)} variant="danger">
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default UserForm;
