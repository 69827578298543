import { notification, Spin } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { api, endpoint } from "../../api";

const EditBTModal = (props) => {
  const { btDataToEdit, getBuildingTypes, setModalShow } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [buildingTypeData, setBuildingTypeData] = useState({
    name: "",
  });
  const { name } = buildingTypeData;
  const onInputChange = (e) => {
    setBuildingTypeData({
      ...buildingTypeData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { message } = await api.put(
        endpoint.update_building_type + btDataToEdit._id,
        buildingTypeData
      );
      notification.success({ message, placement: "bottomLeft" });
      getBuildingTypes();
      setModalShow(false);
    } catch (error) {
      notification.warning({
        message: "Something went wrong.",
        placement: "bottomLeft",
      });
    }
    setIsLoading(false);

    // const response = await axios.put(
    //   `${process.env.REACT_APP_API_URL}/building-type/` + btDataToEdit._id,
    //   buildingTypeData,
    //   { withCredentials: true }
    // );
    // if (response) {
    //   setIsLoading(false);
    //   setSuccessMessage("Building Type Information Update Successfully");
    //   getBuildingTypes();
    //   setTimeout(() => {
    //     setSuccessMessage();
    //     setModalShow(false);
    //   }, 2000);
    // }
  };
  useEffect(() => {
    setBuildingTypeData({ name: btDataToEdit?.name });
  }, [btDataToEdit]);
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Spin spinning={isLoading}>
          <h5 className="text-center">Update Building Type</h5>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                name="name"
                value={name}
                onChange={onInputChange}
                className="form-control"
                id="name"
                placeholder="Enter a name"
                required
              />
            </div>
            <div className="float-end">
              <button type="submit" className="btn btn-success me-2">
                Save
              </button>
            </div>
          </form>
        </Spin>
      </Modal.Body>
    </Modal>
  );
};

export default EditBTModal;
