import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import NotificationSidebar from "../../components/Notifications/NotificationSidebar";
import { FiTrash, FiEye, FiEdit } from "react-icons/fi";
import { MdOutlineDevicesOther } from "react-icons/md";
import moment from "moment/moment";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { ThemeContext } from "../../App.js";
import { api, endpoint } from "../../api";
import { Spin } from "antd";

const AlarmSummary = () => {
  let { isDark } = useContext(ThemeContext);

  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const getNotifications = async () => {
    setIsLoading(true);
    try {
      let { results, count } = await api.get(endpoint.notification, {
        params: { limit, page, sortBy: "date", orderBy: "DESC" },
      });

      setNotifications(results);
      setTotal(count || 0);
    } catch (error) {}
    setIsLoading(false);
  };

  useEffect(() => {
    document.title = "SEDA - System Alarm Summary";
    getNotifications();
  }, [page, limit]);

  const columns = [
    {
      name: "No.",
      cell: (row, index) => (page - 1) * limit + index + 1,
      width: "60px",
      center: true,
    },
    {
      name: "Alarm Name",
      cell: (row) => <div>{row?.name}</div>,
    },
    {
      name: "Device Name",
      cell: (row) => row?.device?.name,
      width: "150px",
    },
    {
      name: "Device Type",
      cell: (row) => row?.device?.deviceType?.name,
    },
    {
      name: "Site Name",
      cell: (row) => <>{row?.site?.name}</>,
      grow: 2,
      selector: (row) => row?.site,
    },
    // {
    //   name: "Installer",
    //   cell: (row) => <>{row?.site?.installer?.name}</>,
    //   selector: (row) => row?.site,
    // },
    // {
    //   name: "Site Admin",
    //   cell: (row) => <>{row?.site?.admin?.name}</>,
    //   selector: (row) => row?.site,
    // },
    {
      name: "Date Alarm Created",
      selector: (row) => moment(row?.device?.createdAt).format("DD/MM/YYYY"),
      // grow: 2,
      center: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <Link to={`/alarm-view/` + row?._id} title='View Alarm' className="btn btn-success me-1">
            <FiEye />
          </Link>
          <Link to={`/devices/details/` + row?.device?._id} title='View Device' className="btn btn-warning me-1">
            <MdOutlineDevicesOther />
          </Link>
          <Link to={`/edit-alarm/` + row?._id} className="btn btn-info me-1">
            <FiEdit title="Edit" />
          </Link>
          <button className="btn btn-danger" onClick={() => deleteAlarm(row?._id)}>
            <FiTrash title="Delete" />
          </button>
        </div>
      ),
      grow: 2,
      center: "yes",
    },
  ];

  const deleteAlarm = async (alarmId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Alarm?",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_API_URL}/notification/` + alarmId, {
            withCredentials: true,
          })
          .then((res) => {
            getNotifications();
            Swal.fire({
              title: "Done!",
              text: "Alarm Deleted Successfully",
              icon: "success",
              timer: 2000,
              button: false,
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  return (
    <div className="settings">
      <div className="container-fluid">
        <div className="row my-5 vh60">
          <div className="col-md-2">
            <NotificationSidebar />
          </div>
          <div className="col-md-10">
            <div className="card p-3 mb-3">
              <h4>System Alarm Summary</h4>
              <Spin spinning={isLoading}>
                <DataTable
                  noDataComponent={isLoading ? "Loading" : "No record found."}
                  columns={columns}
                  data={notifications}
                  pagination
                  paginationServer
                  striped={!isDark}
                  theme={isDark ? "dark" : "light "}
                  paginationTotalRows={total}
                  paginationPerPage={limit}
                  onChangePage={(data) => {
                    setPage(data);
                  }}
                  onChangeRowsPerPage={(data) => {
                    setLimit(data);
                    setPage(1);
                  }}
                  paginationRowsPerPageOptions={[10, 20, 50]}
                />
              </Spin>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlarmSummary;
