import { useEffect, useState, createContext } from "react";
import { useFullScreenHandle } from "react-full-screen";
import Header from "./components/Layout/Header";
import Navbar from "./components/Layout/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { useSelector } from "react-redux";
import Footer from "./components/Layout/Footer";
import { userRole } from "./constant";

import SetupAdmin from "./pages/Setup/SetupAdmin";
import ForgotPassword from "./pages/SignIn/ForgotPassword";
import Dashboard from "./pages/Dashboard/Dashboard";
import SignIn from "./pages/SignIn/SignIn";
import DeviceComparison from "./pages/AnalysisReporting/DeviceComparison";
import DashboardTwo from "./pages/Dashboard/DashboardTwo";
import DashboardThree from "./pages/Dashboard/DashboardThree";
import DashboardFour from "./pages/Dashboard/DashboardFour";
import InstallerSignUp from "./pages/SignIn/InstallerSignUp";
import AlarmSummary from "./pages/Notifications/AlarmSummary";
import CreateAlarm from "./pages/Notifications/CreateAlarm";
import RecipientList from "./pages/Notifications/RecipientList";
import AlarmView from "./pages/Notifications/AlarmView";
import EditAlarm from "./pages/Notifications/EditAlarm";
import VerifyEmail from "./pages/SignIn/VerifyEmail";

import UserList from "./pages/Users/UserList";
import UserDetails from "./pages/Users/UserDetails";
import UserCreate from "./pages/Users/UserCreate";
import UserEdit from "./pages/Users/UserEdit";
import Users from "./pages/Users";

import SiteLocations from "./pages/SiteLocation";
import SiteLocationsList from "./pages/SiteLocation/SiteLocationsList";
import AddSiteLocation from "./pages/SiteLocation/AddSiteLocation";
import SiteLocationView from "./pages/SiteLocation/SiteLocationView";
import EditSiteLocation from "./pages/SiteLocation/EditSiteLocation";
import SiteDocuments from "./pages/SiteLocation/SiteDocuments";

import Devices from "./pages/Devices";
import AddDevice from "./pages/Devices/AddDevice";
import DeviceDetails from "./pages/Devices/DeviceDetails";
import EditDevice from "./pages/Devices/EditDevice";
import DeviceTypes from "./pages/Devices/DeviceTypes";
import DeviceList from "./pages/Devices/DeviceList";

import Settings from "./pages/Settings";
import ChangePassword from "./pages/Settings/ChangePassword";
import ElectricityTariff from "./pages/Settings/ElectricityTariff";
import SystemComputation from "./pages/Settings/SystemComputation";
import DashboardSettings from "./pages/Settings/DashboardSettings";
import Parameters from "./pages/Settings/Parameters/Parameters";
import ManageFormulas from "./pages/Settings/Formula/ManageFormulas";
import BuildingBackgroundTypes from "./pages/Settings/BuildingTypes/BuildingBackgroundTypes";

import Profile from "./pages/Profile/Profile";
import AnalysisReporting from "./pages/AnalysisReporting/AnalysisReporting";
import EditProfile from "./pages/Profile/EditProfile";
import ResetPassword from "./pages/SignIn/ResetPassword";
import DeviceData from "./pages/Devices/DeviceData";

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-awesome-lightbox/build/style.css";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "./styles/index.scss";
import GraphSetting from "./pages/Settings/GraphSetting";

import CreateDashboard from "./pages/MultipleDashboard/CreateDashboard";
import DashboardList from "./pages/MultipleDashboard/DashboardList";
import AssignDashboard from "./pages/MultipleDashboard/AssignDashboard";
import DashboardSettingsHome from "./pages/Settings/DashboardSettingsHome";
import EditDashboard from "./pages/MultipleDashboard/EditDashboard";
import MultiDashboard from "./pages/MultipleDashboard/MultiDashboard";
import { ConfigProvider, theme } from "antd";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const ThemeContext = createContext({});

// Custom light themes
const customLightTheme = {
  token: {
    colorPrimary: "#0d6efd",
    colorPrimaryHover: "#0c60d0",
    // colorBgDisabled: "#4b93fd",
    // colorBgContainerDisabled: "#4b93fd",
    colorText: "var(--color-black)",
    button: {
      colorBgContainerDisabled: "#4b93fd",
      colorTextDisabled: "white",
    },
  },
  algorithm: theme.defaultAlgorithm,
};

// custom dark theme
const customDarkTheme = {
  token: {
    colorBgBase: "#101114",
    colorTextBase: "#dee2e6",
    colorWarning: "#ffc107",
    colorError: "#dc3545",
    colorSuccess: "#198754",
    colorPrimary: "#0d6efd",
    colorPrimaryHover: "#0c60d0",
    colorBgDisabled: "#4b93fd",
    colorInfo: "#0dcaf0",
    wireframe: false,
    fontSize: 16,
    sizeStep: 5,
  },
  algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
};

function App() {
  // const sessionAge = 10 * 60 * 60 * 1000 //10 minute
  let d = JSON.parse(localStorage.getItem("isDark"));
  const [isDark, setIsDark] = useState(!!d || false);

  useEffect(() => {
    setIsDark(!!d || false);
    if (isDark) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [isDark, d]);

  const isLogged = useSelector((state) => state.user?.isLogged);
  const user = useSelector((state) => state?.user);
  const handle = useFullScreenHandle();

  return (
    <ThemeContext.Provider value={{ isDark, setIsDark }}>
      <ConfigProvider theme={isDark ? customDarkTheme : customLightTheme}>
        <BrowserRouter>
          <div className="App dark-mode">
            {isLogged ? (
              <>
                <Header handle={handle} isDark={isDark} setIsDark={setIsDark} />

                <Navbar handle={handle} />
                <Routes>
                  {(() => {
                    switch (Number(user?.currentDashboardtype)) {
                      case 1:
                        return <Route path="" element={<Dashboard handle={handle} />} />;
                      case 2:
                        return <Route path="" element={<DashboardTwo handle={handle} />} />;
                      case 3:
                        return <Route path="" element={<DashboardThree handle={handle} />} />;
                      case 4:
                        return <Route path="" element={<DashboardFour handle={handle} />} />;
                      default:
                        return user?.userDetails?.role === userRole.public ? (
                          <Route path="" element={<DashboardThree handle={handle} />} />
                        ) : Boolean(user?.currentDashboardtype) ? (
                          <Route path="" element={<MultiDashboard handle={handle} />} />
                        ) : (
                          <Route path="" element={<Dashboard handle={handle} />} />
                        );
                    }
                  })()}

                  <Route path="users" element={<Users />}>
                    <Route index element={<UserList />} />
                    <Route path="create" element={<UserCreate />} />
                    <Route path="edit/:userId" element={<UserEdit />} />
                    <Route path="details/:userId" element={<UserDetails />} />
                  </Route>

                  <Route path="site-locations" element={<SiteLocations />}>
                    <Route index element={<SiteLocationsList />} />
                    <Route path="create" element={<AddSiteLocation />} />
                    <Route path="details/:siteLocationId" element={<SiteLocationView />} />
                    <Route path="edit/:siteLocationId" element={<EditSiteLocation />} />
                    <Route path="document/:siteLocationId" element={<SiteDocuments />} />
                  </Route>

                  <Route path="devices" element={<Devices />}>
                    <Route index element={<DeviceList />} />
                    <Route path="add-device" element={<AddDevice />} />
                    <Route path="device-types" element={<DeviceTypes />} />
                    <Route path="details/:deviceId" element={<DeviceDetails />} />
                    <Route path="edit/:deviceId" element={<EditDevice />} />
                    <Route path="data/:deviceId" element={<DeviceData />} />
                  </Route>

                  <Route path="settings" element={<Settings />}>
                    <Route index element={<ChangePassword />} />
                    <Route path="electricity-tariff" element={<ElectricityTariff />} />
                    <Route path="system-computation" element={<SystemComputation />} />

                    <Route path="dashboard-settings" element={<DashboardSettingsHome />}>
                      <Route index element={<DashboardSettings />} />
                      <Route path="newDashboard" element={<CreateDashboard />} />
                      <Route path="dashboardList" element={<DashboardList />} />
                      <Route path="assignDashboard" element={<AssignDashboard />} />
                      <Route path="dashboardEdit/:type" element={<EditDashboard />} />
                    </Route>

                    <Route path="building-background-types" element={<BuildingBackgroundTypes />} />
                    <Route path="parameters" element={<Parameters />} />
                    <Route path="formulas" element={<ManageFormulas />} />
                    <Route path="graph-settings" element={<GraphSetting />} />
                  </Route>

                  {/* site Location related routes */}
                  {/* <Route path="site-location/:siteLocationId" element={<SiteLocationView />} />
                <Route path="edit-site-location/:siteLocationId" element={<EditSiteLocation />} />
                <Route path="site-document/:siteLocationId" element={<SiteDocuments />} />
                <Route path="site-locations" element={<SiteLocations />} />
                <Route path="add-location" element={<AddSiteLocation />} /> */}

                  {/* Devices related routes */}
                  {/* <Route path="devices" element={<Devices />} />
                <Route path="add-device" element={<AddDevice />} />
                <Route path="add-virtual-device" element={<AddVirtualDevice />} />
                <Route path="device-types" element={<DeviceTypes />} />
                <Route path="device/:deviceId" element={<DeviceView />} />
                <Route path="viewdevices" element={<VirtualDeviceView />} />
                <Route path="edit-device/:deviceId" element={<EditDevice />} />
                <Route path="device-data/:deviceId" element={<DeviceData />} /> */}

                  {/* Analysis &Reporting related routes */}
                  <Route path="analysis-reporting" element={<AnalysisReporting />} />
                  <Route path="device-comparison" element={<DeviceComparison />} />

                  {/* Settings related routes */}

                  {/* Profile */}
                  <Route path="profile" element={<Profile />} />
                  <Route path="edit-profile" element={<EditProfile />} />

                  {/* Notification related routes */}
                  <Route path="alarm-summary" element={<AlarmSummary />} />
                  <Route path="create-alarm" element={<CreateAlarm />} />
                  <Route path="recipient-list" element={<RecipientList />} />
                  <Route path="alarm-view/:alarmId" element={<AlarmView />} />
                  <Route path="edit-alarm/:alarmId" element={<EditAlarm />} />
                  <Route path="verification" element={<VerifyEmail />} />
                  <Route path="*" element={<p> route not found go to home page </p>} />
                </Routes>
                <Footer />
              </>
            ) : (
              <Routes>
                <Route path="" element={<SignIn />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="reset-password/:token" element={<ResetPassword />} />
                <Route path="installer-signup" element={<InstallerSignUp />} />
                <Route path="verification" element={<VerifyEmail />} />
                <Route path="admin" element={<SetupAdmin />} />

                <Route path="*" element={<p> route not found please login or go to home page </p>} />
              </Routes>
            )}
          </div>
        </BrowserRouter>
      </ConfigProvider>
    </ThemeContext.Provider>
  );
}

export default App;
