import React, { useEffect } from "react";
import { DatePicker } from "antd";

export const TimeFilter = ({ viewType, setViewType, dateRange, setDateRange }) => {
  const onChangeValue = (date) => {
    setDateRange(date);
  };

  return (
    <div className="row">
      <div className="col-md-12 mb-2">
        <div className="text-center">
          <CustomBtn name="today" viewType={viewType} setViewType={setViewType} />
          <CustomBtn name="weekly" viewType={viewType} setViewType={setViewType} />
          <CustomBtn name="monthly" viewType={viewType} setViewType={setViewType} />
          <CustomBtn name="yearly" viewType={viewType} setViewType={setViewType} />
          <CustomBtn name="custom" viewType={viewType} setViewType={setViewType} />
        </div>
      </div>
      <div className="col-md-12" style={{ visibility: viewType !== "custom" ? "hidden" : "visible" }}>
        <div className="row d-flex justify-align-center">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <DatePicker.RangePicker
              // disabled={viewType !== "custom"}
              // showTime
              showNow
              className="w-100"
              size="middle"
              value={dateRange}
              onChange={onChangeValue}
              format="DD MMM,YY"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomBtn = ({ name, viewType, setViewType }) => (
  <button
    className={`text-capitalize btn btn-${viewType === name ? "success" : "secondary"} me-2`}
    onClick={() => setViewType(name)}
  >
    {name}
  </button>
);
