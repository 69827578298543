import React from "react";
import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import moment from "moment";

// Define styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    // fontFamily: "Helvetica",
  },
  header: {
    marginBottom: 20,
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  logo: {
    width: 80,
    height: 80,
    margin: "0 auto",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    // textAlign: "center",
    marginBottom: 5,
  },
  subtitle: {
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "left",
    // marginBottom: 15,
  },
  section: {
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
    gap: 10,
    justifyContent: "between",
  },
  label: {
    fontWeight: "bold",
    borderBottom: "1px solid black",
    fontSize: 18,
    marginBottom: 10,
  },
  table: {
    marginTop: 20,
    borderWidth: 1,
    borderColor: "#000",
    borderStyle: "solid",
  },
  tableHeader: {
    flexDirection: "row",
    backgroundColor: "#f0f0f0",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
  },
  tableCell: {
    padding: 5,
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: "#000",
    borderRightStyle: "solid",
    textAlign: "center",
  },

  footer: {
    position: "absolute",
    bottom: 30,
    left: 0,
    fontSize: 10,
    textAlign: "center",
    width: "100%",
    color: "gray",
  },
});

// Create the PDF Document
const UserProfilePDF = ({ user = {} }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          <View>
            <Image
              src="/images/logo.png" // Replace with SEDA logo URL
              style={styles.logo}
            />
          </View>
          <View>
            <Text style={styles.title}>ONLINE ENERGY MONITORING CLOUD PLATFORM</Text>
            <Text style={styles.subtitle}>
              PIHAK BERKUASA PEMBANGUNAN TENAGA LESTARI SUSTAINABLE ENERGY {"\n"} DEVELOPMENT AUTHORITY (SEDA) MALAYSIA
            </Text>
          </View>
        </View>

        <View style={{ display: "flex", flexDirection: "row", fontSize: 10, marginBottom: 10 }}>
          <Text style={{ marginLeft: "auto" }}> Date Generated: {moment().format("DD/MM/YYYY HH:mm:ss")} </Text>
        </View>

        {/* User Profile Section */}
        <View>
          <Text style={styles.label}>User Profile</Text>
          <View style={styles.section}>
            <View>
              {user?.name && <Text style={{ marginBottom: 10 }}>Full Name: {user.name}</Text>}
              {user?.role && <Text style={{ marginBottom: 10 }}>User Type: {user.role}</Text>}
              {user?.email && <Text style={{ marginBottom: 10 }}>Email Address: {user.email}</Text>}
              {user?.phone && <Text style={{ marginBottom: 10 }}>Phone Number: {user.phone}</Text>}
              {user?.companyName && <Text style={{ marginBottom: 10 }}>Company Name: {user.companyName}</Text>}
              {user?.companyAddress && <Text style={{ marginBottom: 10 }}>Company Address: {user.companyAddress}</Text>}
              <Text style={{ marginBottom: 10 }}>Status: {user.status ? "Active" : "Deactivated"}</Text>
            </View>
            <View style={{ marginLeft: "auto" }}>
              <Image
                src={user.avatar?.includes("cloudinary") ? user?.avatar : user?.avatar + ".png"}
                alt="logo"
                style={styles.logo}
                cache={true}
              />
            </View>
          </View>
        </View>

        {/* Table */}
        <View style={styles.table}>
          {/* Header */}
          <View style={styles.tableHeader}>
            <Text style={[styles.tableCell, { flex: 0.2 }]}>No</Text>
            <Text style={styles.tableCell}>Site Location</Text>
            <Text style={styles.tableCell}>Date Assigned</Text>
          </View>
          {/* Data */}

          {Array.isArray(user?.site) &&
            user?.site?.map((s, index) => {
              console.log({ s });
              return (
                <View key={index} style={styles.tableRow}>
                  <Text style={[styles.tableCell, { flex: 0.2 }]}>{index + 1}</Text>
                  <Text style={styles.tableCell}>{s?.name}</Text>
                  <Text style={styles.tableCell}>{moment(s?.updated).format("DD/MM/YYYY")}</Text>
                </View>
              );
            })}
        </View>

        {/* Footer */}
        <Text style={styles.footer}>
          This file was generated from SEDA OEMS (https://monitoring.seda.gov.my)
          {"\n"}© 2025 SEDA Malaysia. All Rights Reserved
        </Text>
      </Page>
    </Document>
  );
};

export default UserProfilePDF;
