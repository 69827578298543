import { notification, Spin } from "antd";
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { api, endpoint } from "../../api";
import { setDashboardDetails } from "../../redux/dashboardSlice";
import SelectedDashboardSettings from "./SelectedDashboardSettings/SelectedDashboardSettings";
import SelectedDashboardSettings4 from "./SelectedDashboardSettings/SelectedDashboardSettings4";

const DashboardSettings = () => {
  const { userDetails } = useSelector((state) => state.user);
  const dashboards = useSelector((state) => state.dashboard);

  const dispatch = useDispatch();
  // state
  const [template, setTemplate] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [allDashboardDetails, setAllDashboardDetails] = useState([]);
  const [state, setState] = useState({});

  const dashboardDetails = useMemo(() => {
    return dashboards[template];
  }, [template, dashboards]);

  useEffect(() => {
    setState(dashboardDetails);
  }, [dashboardDetails, template]);

  // useEffect(() => {
  //   if (Number(currentDashboardtype) > 0) {
  //     setTemplate(Number(currentDashboardtype));
  //   }
  // }, [currentDashboardtype]);

  const getAllDashboardDetails = async () => {
    setIsLoading(true);
    try {
      const { results } = await api.get(endpoint.get_all_dashboard_setting);
      setAllDashboardDetails(results);
    } catch (error) {}
    setIsLoading(false);
  };

  const onUpdateDashboard = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (template === 4) {
        let backgroundImage = dashboardDetails.backgroundImage;
        if (dashboardDetails.backgroundImageFile) {
          const formData = new FormData();
          formData.append("file", dashboardDetails.backgroundImageFile);
          backgroundImage = await api.post("/file/upload", formData);
        }
        await api.put(endpoint.update_dashboard_settings, {
          dashboardType: template,
          name: dashboardDetails?.name,
          backgroundImage: backgroundImage,
          cards: dashboardDetails.cards.map((d) => ({ ...d, _id: undefined })),
        });
      } else {
        await api.put(endpoint.update_dashboard_settings, {
          dashboardType: template,
          baseLoad: state?.baseLoad,
          userType: state?.userType,
          name: state?.name,
          graphs: state.graphs,
        });
      }
      getAllDashboardDetails();
      notification.success({
        message: "Dashboard has been updated.",
        placement: "bottomLeft",
      });
    } catch (error) {
      notification.error({
        message: "something went wrong.",
        placement: "bottomLeft",
      });
    }
    setIsLoading(false);
  };
  const handleChangeTemplate = ({ name, value }) => {
    setState({ ...state, [name]: value });
  };

  useEffect(() => {
    // getDashboardDetails();
    const dashboard = allDashboardDetails.find((item) => Number(item?.dashboardType) === Number(template));
    if (dashboard) {
      dispatch(setDashboardDetails(dashboard));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template, allDashboardDetails]);

  useEffect(() => {
    document.title = "Seda - Dashboard Settings";

    getAllDashboardDetails();
  }, []);


  return (
    <Spin spinning={isLoading}>
      <div className="card p-3 mb-3">
        <h4>Dashboard Settings</h4>
        <h5>Choose a Template</h5>
        <div className="row mt-3">
          <div className="col-md-3">
            <input
              className="form-check-input d-none"
              type="radio"
              value={1}
              name="dashboard"
              id="option1"
              onChange={() => setTemplate(1)}
            />
            <label className="form-check-label" htmlFor="option1">
              <div className={`${template === 1 ? "text-center border border-3 border-success" : "text-center"}`}>
                <img src="/images/1.png" alt={"Dashboard 1"} height={130} />
                <p>{allDashboardDetails.find((item) => item?.dashboardType === 1)?.name || "Dashboard 1"}</p>
              </div>
            </label>
          </div>
          {/* <div className={`${userDetails?.role === "superAdmin" ? "col-md-3" : "d-none"}`}>
            <input
              className="form-check-input d-none"
              type="radio"
              value={2}
              name="dashboard"
              id="option2"
              onChange={() => setTemplate(2)}
            />

            <label className="form-check-label" htmlFor="option2">
              <div className={`${template === 2 ? "text-center border border-3 border-success" : "text-center"}`}>
                <img src="/images/1.png" alt={"Dashboard 1"} height={130} />
                <p>{allDashboardDetails.find((item) => item?.dashboardType === 2)?.name || "Dashboard 2"}</p>
              </div>
            </label>
          </div> */}
          <div className="col-md-3">
            <input
              className="form-check-input d-none"
              type="radio"
              value={3}
              name="dashboard"
              id="option3"
              onChange={() => setTemplate(3)}
            />
            <label className="form-check-label" htmlFor="option3">
              <div className={`${template === 3 ? "text-center border border-3 border-success" : "text-center"}`}>
                <img src="/images/3.png" alt="Dashboard 3" height={130} />
                <p>{allDashboardDetails.find((item) => item?.dashboardType === 3)?.name || "Dashboard 3"}</p>
              </div>
            </label>
          </div>
          {/* <div className="col-md-3">
            <input
              className="form-check-input d-none"
              type="radio"
              value={4}
              name="dashboard"
              id="option4"
              onChange={() => setTemplate(4)}
            />
            <label className="form-check-label" htmlFor="option4">
              <div className={`${template === 4 ? "text-center border border-3 border-success" : "text-center"}`}>
                <img src="/images/4.png" alt="Dashboard 4" height={130} />

                <p>{allDashboardDetails.find((item) => item?.dashboardType === 4)?.name || "Dashboard 4"}</p>
              </div>
            </label>
          </div> */}
        </div>

        <div className="my-4" style={{ width: "100%", backgroundColor: "aliceblue" }}>
          {template === 4 ? (
            <SelectedDashboardSettings4
              handleChangeTemplate={handleChangeTemplate}
              // onUpdateDashboard={onUpdateDashboard}
              dashboardType={4}
            />
          ) : (
            <SelectedDashboardSettings
              dashboardDetails={state}
              handleChangeTemplate={handleChangeTemplate}
              // onUpdateDashboard={onUpdateDashboard}
              dashboardType={template}
            />
          )}
        </div>
        <div className="row mt-3">
          <div className="col-md-12 d-flex justify-content-end">
            <button className="btn btn-success" onClick={onUpdateDashboard}>
              Set Dashboard
            </button>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default DashboardSettings;
