import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const SettingSidebarNav = () => {
  const userDetails = useSelector((state) => state.user.userDetails);
  return (
    <div>
      <h4 className="mb-4">Settings</h4>
      {(() => {
        switch (userDetails.role) {
          case "superAdmin":
            return (
              <ul className="list-group mb-3" style={{fontSize: '12px'}}>
                <li className="list-group-item">
                  <NavLink
                    to="/settings"
                    className="text-dark text-decoration-none"
                    style={({ isActive }) => (isActive ? { fontWeight: "bold" } : {})}
                    end
                  >
                    Change Password
                  </NavLink>
                </li>
                <li className="list-group-item">
                  <NavLink
                    to="/settings/electricity-tariff"
                    className="text-dark text-decoration-none"
                    style={({ isActive }) => (isActive ? { fontWeight: "bold" } : {})}
                  >
                    Electricity Tariff
                  </NavLink>
                </li>
                <li className="list-group-item">
                  <NavLink
                    to="/settings/parameters"
                    className="text-dark text-decoration-none"
                    style={({ isActive }) => (isActive ? { fontWeight: "bold" } : {})}
                  >
                    Manage System Parameters
                  </NavLink>
                </li>
                <li className="list-group-item">
                  <NavLink
                    to="/settings/formulas"
                    className="text-dark text-decoration-none"
                    style={({ isActive }) => (isActive ? { fontWeight: "bold" } : {})}
                  >
                    Manage Formulas
                  </NavLink>
                </li>

                <li className='list-group-item'>
                  <NavLink to='/settings/dashboard-settings' className='text-dark text-decoration-none fw-bold'>Dashboard Settings</NavLink>
                  <ul className="sub_nav">
                    <li className='list-group-item border-0'>
                      <NavLink
                        to='/settings/dashboard-settings/newDashboard'
                        className='text-dark text-decoration-none'
                      >Create Dashboard</NavLink>
                    </li>
                    <li className='list-group-item border-0'>
                      <NavLink
                        to='/settings/dashboard-settings/dashboardList'
                        className='text-dark text-decoration-none'
                      >Dashboard List</NavLink>
                    </li>
                    <li className='list-group-item border-0'>
                      <NavLink
                        to='/settings/dashboard-settings/assignDashboard'
                        className='text-dark text-decoration-none'
                      > Assign Dashboard </NavLink>
                    </li>
                  </ul>
                </li>

                <li className="list-group-item">
                  <NavLink
                    to="/settings/graph-settings"
                    className="text-dark text-decoration-none"
                    style={({ isActive }) => (isActive ? { fontWeight: "bold" } : {})}
                  >
                    Default Graph Settings
                  </NavLink>
                </li>
                <li className="list-group-item">
                  <NavLink
                    to="/settings/building-background-types"
                    className="text-dark text-decoration-none"
                    style={({ isActive }) => (isActive ? { fontWeight: "bold" } : {})}
                  >
                    Building Background Types
                  </NavLink>
                </li>
              </ul>
            );
          case "installer":
            return (
              <ul className="list-group mb-3">
                <li className="list-group-item">
                  <NavLink
                    to="/settings"
                    className="text-dark text-decoration-none"
                    style={({ isActive }) => (isActive ? { fontWeight: "bold" } : {})}
                  >
                    Change Password
                  </NavLink>
                </li>
              </ul>
            );
          case "admin":
            return (
              <ul className="list-group mb-3">
                <li className="list-group-item">
                  <NavLink
                    to="/settings"
                    className="text-dark text-decoration-none"
                    style={({ isActive }) => (isActive ? { fontWeight: "bold" } : {})}
                  >
                    Change Password
                  </NavLink>
                </li>
                <li className="list-group-item">
                  <NavLink
                    to="/settings/electricity-tariff"
                    className="text-dark text-decoration-none"
                    style={({ isActive }) => (isActive ? { fontWeight: "bold" } : {})}
                  >
                    Electricity Tariff
                  </NavLink>
                </li>
                {/* <li className="list-group-item">
                  <NavLink
                    to="/settings/dashboard-settings"
                    className="text-dark text-decoration-none"
                    style={({ isActive }) => (isActive ? { fontWeight: "bold" } : {})}
                  >
                    Dashboard Settings
                  </NavLink>
                </li> */}
              </ul>
            );
          case "user":
            return (
              <ul className="list-group mb-3">
                <li className="list-group-item">
                  <NavLink
                    to="/settings"
                    className="text-dark text-decoration-none"
                    style={({ isActive }) => (isActive ? { fontWeight: "bold" } : {})}
                  >
                    Change Password
                  </NavLink>
                </li>
                <li className="list-group-item">
                  <NavLink
                    to="/settings/electricity-tariff"
                    className="text-dark text-decoration-none"
                    style={({ isActive }) => (isActive ? { fontWeight: "bold" } : {})}
                  >
                    Electricity Tariff
                  </NavLink>
                </li>
              </ul>
            );
          case "public":
            return (
              <ul className="list-group mb-3">
                <li className="list-group-item">
                  <NavLink
                    to="/settings"
                    className="text-dark text-decoration-none"
                    style={({ isActive }) => (isActive ? { fontWeight: "bold" } : {})}
                  >
                    Change Password
                  </NavLink>
                </li>
                <li className="list-group-item">
                  <NavLink
                    to="/settings/electricity-tariff"
                    className="text-dark text-decoration-none"
                    style={({ isActive }) => (isActive ? { fontWeight: "bold" } : {})}
                  >
                    Electricity Tariff
                  </NavLink>
                </li>
              </ul>
            );
          default:
            return;
        }
      })()}
    </div>
  );
};

export default SettingSidebarNav;
