import React from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { useSearchQuery } from "../../../hooks/useSearchQuery";

const UsersSidebar = () => {
  const userDetails = useSelector((state) => state.user.userDetails);
  const role = useSearchQuery("role");

  const activeStyle = (r) => {
    if (r === role || (!r && role === "all")) {
      return {
        fontWeight: "bold",
      };
    }
  };

  let sidebar;

  if (userDetails.role === "superAdmin") {
    sidebar = (
      <ul className="list-group mb-3">
        <li className="list-group-item">
          <NavLink to="/users" className="text-dark text-decoration-none " style={activeStyle(null)}>
            All Users
          </NavLink>
        </li>
        <li className="list-group-item">
          <NavLink
            to={`/users?role=installer`}
            className="text-dark text-decoration-none"
            style={activeStyle("installer")}
          >
            Manage Installers
          </NavLink>
        </li>
        <li className="list-group-item">
          <NavLink to={`/users?role=admin`} className="text-dark text-decoration-none" style={activeStyle("admin")}>
            Manage Admins
          </NavLink>
        </li>
        <li className="list-group-item">
          <NavLink to={`/users?role=user`} className="text-dark text-decoration-none" style={activeStyle("user")}>
            Manage Site Users
          </NavLink>
        </li>
        <li className="list-group-item">
          <NavLink to={`/users?role=public`} className="text-dark text-decoration-none" style={activeStyle("public")}>
            Manage Public Users
          </NavLink>
        </li>
      </ul>
    );
  }

  if (userDetails.role === "admin") {
    sidebar = (
      <ul className="list-group mb-3">
        <li className="list-group-item">
          <Link to={`/users?role=installer`} className="text-dark text-decoration-none">
            Manage Installers
          </Link>
        </li>
        <li className="list-group-item">
          <Link to={`/users?role=user`} className="text-dark text-decoration-none">
            Manage Site Users
          </Link>
        </li>
        <li className="list-group-item">
          <Link to={`/users?role=public`} className="text-dark text-decoration-none">
            Manage Public Users
          </Link>
        </li>
      </ul>
    );
  }

  return (
    <div>
      <h4 className="mb-4">Manage Users</h4>
      {sidebar}
    </div>
  );
};

export default UsersSidebar;
