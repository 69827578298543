import axios from "axios";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const SiteLocationForm = ({ mode, data }) => {
  const [SuccessMessage, setSuccessMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [buildingTypes, setBuildingTypes] = useState([]);
  const userDetails = useSelector((state) => state.user.userDetails);
  const [Admins, setAdmins] = useState([]);
  const [Installers, setInstallers] = useState([]);
  const [ElectricityTariff, setElectricityTariff] = useState([]);
  const [siteLocationData, setSiteLocationData] = useState({
    name: "",
    // admin: [],
    // installer: [],
    buildingName: "",
    buildingType: "",
    buildingAddress1: "",
    buildingAddress2: "",
    buildingPostalCode: "",
    buildingOwnerName: "",
    buildingOwnerEmail: "",
    contactPersonName: "",
    contactPersonPhone: "",
    localAuthority: "",
    netFloorArea: "",
    tariffElectricity: "",
    remark: "",
    buildingBackground: "",
    baseLoad: '',
  });
  const {
    name,
    // admin,
    // installer,
    buildingName,
    buildingType,
    buildingAddress1,
    buildingAddress2,
    buildingPostalCode,
    buildingOwnerName,
    buildingOwnerEmail,
    contactPersonName,
    contactPersonPhone,
    localAuthority,
    netFloorArea,
    tariffElectricity,
    remark,
    buildingBackground,
    baseLoad,
  } = siteLocationData;

  useEffect(() => {
    console.log(mode, data);
    if (mode === "edit" && data) {
      setSiteLocationData({
        name: data?.name,
        // admin: Array.isArray(data?.admin) ? data?.admin?.map((d) => d._id) : [],
        // installer: Array.isArray(data?.installer) ? data?.installer?.map((d) => d._id) : [],
        buildingName: data?.buildingName,
        buildingType: data?.buildingType,
        buildingAddress1: data?.buildingAddress1,
        buildingAddress2: data?.buildingAddress2,
        buildingPostalCode: data?.buildingPostalCode,
        buildingOwnerName: data?.buildingOwnerName,
        buildingOwnerEmail: data?.buildingOwnerEmail,
        contactPersonName: data?.contactPersonName,
        contactPersonPhone: data?.contactPersonPhone,
        localAuthority: data?.localAuthority,
        netFloorArea: data?.netFloorArea,
        tariffElectricity: data?.tariffElectricity?._id,
        remark: data?.remark,
        baseLoad: data?.baseLoad,
        buildingBackground: data?.buildingBackground?._id,
      });
    }
  }, [mode, data]);

  const onInputChange = (e) => {
    setSiteLocationData({
      ...siteLocationData,
      [e.target.name]: e.target.value,
    });
  };

  const onSelectChange = (values) => {
    setSiteLocationData({
      ...siteLocationData,
      ...values,
    });
  };

  const getBuildingTypes = async () => {
    const response = await axios.get(`/building-type`, { withCredentials: true });
    if (response) {
      setBuildingTypes(response.data?.results);
    }
  };
  const getAdmins = async () => {
    const response = await axios.get(`/user/role/admin`, { withCredentials: true });
    if (response) {
      setAdmins(response.data);
    }
  };
  const getInstaller = async () => {
    const response = await axios.get(`/user/role/installer`, {
      withCredentials: true,
    });
    if (response) {
      setInstallers(response.data);
    }
  };
  const getElectricityTariff = async () => {
    const response = await axios.get(`/electricity-tariff`, { withCredentials: true });
    if (response) {
      setElectricityTariff(response.data?.results);
    }
  };
  useEffect(() => {
    document.title = "SEDA - Add A Site Location";
    getElectricityTariff();
    getInstaller();
    getAdmins();
    getBuildingTypes();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userDetails.role === "admin") {
      setSiteLocationData({ ...siteLocationData, admin: userDetails?._id });
    }
    // eslint-disable-next-line
  }, [userDetails]);

  const handleCreate = async () => {
    setIsLoading(true);
    const response = await axios
      .post(`${process.env.REACT_APP_API_URL}/site-location`, siteLocationData, { withCredentials: true })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          if (error.response.status === 400 || 500) {
            console.log(error);
          }
          console.log(error.response.headers);
        }
      });
    const data = response.data;
    if (data) {
      setIsLoading(false);
      setSuccessMessage("Site Location Created Successfully");
      setTimeout(() => {
        setSuccessMessage();
        navigate("/site-locations");
      }, 2000);
    }
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    console.log({ siteLocationData })
    const response = await axios
      .put(`${process.env.REACT_APP_API_URL}/site-location/` + data?._id, siteLocationData, {
        withCredentials: true,
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          if (error.response.status === 400 || 500) {
            console.log(error);
          }
          console.log(error.response.headers);
        }
      });
    if (response.data) {
      setIsLoading(false);
      setSuccessMessage("Site Location Edited Successfully");
      setTimeout(() => {
        setSuccessMessage();
        navigate("/site-locations");
      }, 2000);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (mode === "edit") {
      handleUpdate();
    } else {
      handleCreate();
    }
  };
  return (
    <div className="card p-3">
      <h4 className="mb-4">{mode === "edit" ? "Edit Site Location" : "Add A Site Location"}</h4>
      <div className="d-flex justify-content-center">{isLoading && <Spinner animation="border" variant="dark" />}</div>
      {SuccessMessage && (
        <div className="alert alert-success" role="alert">
          {SuccessMessage}{" "}
        </div>
      )}
      <form onSubmit={submitHandler}>
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="name" className="form-label">
              Site Name
              <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                *
              </span>
            </label>
            <input
              type="text"
              name="name"
              value={name}
              onChange={onInputChange}
              className="form-control"
              id="name"
              placeholder="Enter full name"
              required
            />
          </div>
          {/* {userDetails.role === "admin" ? (
            ""
          ) : (
            <div className="col-md-6">
              <label htmlFor="email" className="form-label">
                Admin
                <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                  *
                </span>
              </label>
              <Select
                showArrow={true}
                showSearch={true}
                style={{ width: "100%" }}
                size="large"
                mode="multiple"
                id="admin"
                name="admin"
                value={admin}
                onChange={(value) => onSelectChange({ admin: value })}
                placeholder="Select Single / Multiple Admin"
                options={[...Admins.map((item) => ({ label: item.name, value: item._id }))]}
                required
              />
            </div>
          )} */}
        </div>
        {/* <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="installer" className="form-label">
              Assign Installer
              <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                *
              </span>
            </label>
            <Select
              showArrow={true}
              showSearch={true}
              style={{ width: "100%" }}
              size="large"
              mode="multiple"
              id="installer"
              name="installer"
              value={installer}
              onChange={(value) => onSelectChange({ installer: value })}
              placeholder="Select Single / Multiple Installer"
              aria-label="Select admins"
              options={[...Installers.map((item) => ({ label: item.name, value: item._id }))]}
              required
            />
          </div>
        </div> */}
        <h5 className="mt-4">Building Information</h5>
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="bname" className="form-label">
              Building Name
              <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                *
              </span>
            </label>
            <input
              type="text"
              name="buildingName"
              value={buildingName}
              onChange={onInputChange}
              className="form-control"
              id="bname"
              placeholder="Enter building name"
              required
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="buildingType" className="form-label">
              Building Type
              <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                *
              </span>
            </label>
            <input
              type="text"
              name="buildingType"
              value={buildingType}
              onChange={onInputChange}
              className="form-control"
              id="buildingType"
              placeholder="Enter building type"
              required
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="buildingAddress1" className="form-label">
              Building Address Line 1
              <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                *
              </span>
            </label>
            <input
              type="text"
              name="buildingAddress1"
              value={buildingAddress1}
              onChange={onInputChange}
              className="form-control"
              id="buildingAddress1"
              placeholder="Enter building address line 1"
              required
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="buildingAddress2" className="form-label">
              Building Address Line 2
            </label>
            <input
              type="text"
              name="buildingAddress2"
              value={buildingAddress2}
              onChange={onInputChange}
              className="form-control"
              id="buildingAddress2"
              placeholder="Enter building address line 2"
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="buildingPostalCode" className="form-label">
              Building Postal Code
              <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                *
              </span>
            </label>
            <input
              type="number"
              name="buildingPostalCode"
              value={buildingPostalCode}
              onChange={onInputChange}
              className="form-control"
              id="buildingPostalCode"
              placeholder="Enter building postal code"
              required
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="localAuthority" className="form-label">
              Local Authority
              <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                *
              </span>
            </label>
            <input
              type="text"
              name="localAuthority"
              value={localAuthority}
              onChange={onInputChange}
              className="form-control"
              id="localAuthority"
              placeholder="Enter local authority"
              required
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="buildingOwnerName" className="form-label">
              Building Owner Name
              <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                *
              </span>
            </label>
            <input
              type="text"
              name="buildingOwnerName"
              value={buildingOwnerName}
              onChange={onInputChange}
              className="form-control"
              id="buildingOwnerName"
              placeholder="Enter building owner name"
              required
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="buildingOwnerEmail" className="form-label">
              Building Owner Email
              <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                *
              </span>
            </label>
            <input
              type="email"
              name="buildingOwnerEmail"
              value={buildingOwnerEmail}
              onChange={onInputChange}
              className="form-control"
              id="buildingOwnerEmail"
              placeholder="Enter building owner email"
              required
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="netFloorArea" className="form-label">
              Nett Floor Area (sq m)
              <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                *
              </span>
            </label>
            <input
              type="number"
              name="netFloorArea"
              value={netFloorArea}
              onChange={onInputChange}
              className="form-control"
              id="netFloorArea"
              placeholder="Enter nett floor area (sq m)"
              required
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="tariffElectricity" className="form-label">
              Tariff Electricity (sen/kWh)
              <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                *
              </span>
            </label>
            <select
              showArrow={true}
              showSearch={true}
              className="form-select"
              id="tariffElectricity"
              name="tariffElectricity"
              value={tariffElectricity}
              onChange={onInputChange}
              required
            >
              <option label="Select tariff Electricity">Select tariff Electricity</option>
              {ElectricityTariff &&
                ElectricityTariff.length > 0 &&
                ElectricityTariff.map((item, index) => (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="remark" className="form-label">
              Remark
            </label>
            <input
              type="text"
              name="remark"
              value={remark}
              onChange={onInputChange}
              className="form-control"
              id="remark"
              placeholder="Enter remark"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="buildingBackground">
              Physical Building Background
              <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                *
              </span>
            </label>
            <select
              showArrow={true}
              showSearch={true}
              className="form-select"
              id="buildingBackground"
              name="buildingBackground"
              value={buildingBackground}
              onChange={onInputChange}
              aria-label="Select a Building Background"
              required
            >
              <option label="Select a Building Background">Select a Building Background</option>
              {buildingTypes &&
                buildingTypes.length > 0 &&
                buildingTypes.map((item, index) => (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <h5 className="mt-4">Contact Person Information</h5>
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="contactPersonName" className="form-label">
              Contact Person Name
              <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                *
              </span>
            </label>
            <input
              type="text"
              name="contactPersonName"
              value={contactPersonName}
              onChange={onInputChange}
              className="form-control"
              id="contactPersonName"
              placeholder="Enter contact person name"
              required
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="contactPersonPhone" className="form-label">
              Contact Person Phone
              <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                *
              </span>
            </label>
            <div className="input-group">
              <span className="input-group-text" id="basic-addon1">
                +6
              </span>
              <input
                type="number"
                name="contactPersonPhone"
                value={contactPersonPhone}
                onChange={onInputChange}
                className="form-control"
                id="contactPersonPhone"
                placeholder="Enter contact person phone"
                required
              />
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <h5 className="mt-4">Graph Setting</h5>
          <div className="col-md-6">
            <label htmlFor="baseLoad" className="form-label">
              Base Load
            </label>
            <input
              type="number"
              name="baseLoad"
              value={baseLoad}
              onChange={onInputChange}
              className="form-control"
              id="baseLoad"
              placeholder="Add Local Baseload"
              required
            />
          </div>
        </div>


        <div className="float-end">
          <button type="submit" className="btn btn-success me-2">
            {mode === "edit" ? "Update" : "Create Site Location"}
          </button>
          <Link to="/site-locations" className="btn btn-secondary">
            Cancel
          </Link>
        </div>
      </form>
    </div>
  );
};

export default SiteLocationForm;
