import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import UsersSidebar from "./components/UserSidebar";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import { Link, Navigate, useParams } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { FiUserCheck, FiUserX, FiTrash, FiEdit, FiEye } from "react-icons/fi";
import { ThemeContext } from "../../App.js";
import { api, endpoint } from "../../api";
import { Form, Select, Spin } from "antd";
import { userRole } from "../../constant";
import { Button, Col, Row } from "react-bootstrap";
import { useSearchQuery } from "../../hooks/useSearchQuery";
import { useSelector } from "react-redux";

const defaultRoleOptions = [
  { label: "Site User", value: "user" },
  { label: "Public User", value: "public" },
];

const UserList = () => {
  let { isDark } = useContext(ThemeContext);
  const { userDetails = {} } = useSelector((state) => state?.user);

   const role = useSearchQuery("role");

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({});
  const [siteLocations, setSiteLocations] = useState([]);

  const [userRoleOptions, setUserListOptions] = useState(defaultRoleOptions);

  useEffect(() => {
    if (userDetails?.role === userRole.admin) {
      setUserListOptions([
        { label: "Admin", value: "admin" },
        { label: "Installer", value: "installer" },
        ...defaultRoleOptions,
      ]);
    }
    if (userDetails.role === userRole.superAdmin) {
      setUserListOptions([
        { label: "Admin", value: "admin" },
        { label: "Installer", value: "installer" },
        ...defaultRoleOptions,
      ]);
    }
    if (userDetails.role === userRole.installer) {
      setUserListOptions([{ label: "Installer", value: "installer" }, ...defaultRoleOptions]);
    }
  }, [userDetails]);

  const getUsers = async () => {
    setIsLoading(true);
    try {
      let { results, count } = await api.get(endpoint.all_users, {
        params: {
          limit,
          page,
          sortBy: "createdAt",
          orderBy: "DESC",
          role: role !== "all" ? role : null,
          ...filter,
        },
      });
      results = results.map((item, idx) => {
        item.sl = (page - 1) * limit + idx + 1;
        return item;
      });
      setUsers(results);
      setTotal(count || 0);
    } catch (error) {}

    setIsLoading(false);
  };

  const getSiteLocations = async () => {
    try {
      const { data } = await axios.get(`/site-location/my-site`, { withCredentials: true });
      if (data) {
        setSiteLocations(data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onConfirmActiveDeactiveUser = async (row) => {
    const data = {
      isActive: !row.isActive,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You want to change user status?",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(`user/` + row._id, data, {
            withCredentials: true,
          })
          .then((res) => {
            getUsers();
            Swal.fire({
              title: "Done!",
              text: "User Status Successfully Changed.",
              icon: "success",
              timer: 2000,
              button: false,
            });
          })
          .catch((err) => {});
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const deleteUser = async (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this user?",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`user/` + userId, {
            withCredentials: true,
          })
          .then((res) => {
            getUsers();
            Swal.fire({
              title: "Done!",
              text: "User Deleted Successfully",
              icon: "success",
              timer: 2000,
              button: false,
            });
          })
          .catch((err) => {});
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  useEffect(() => {
    document.title = "SEDA - Users";
    getUsers();
    // eslint-disable-next-line
  }, [page, limit, role, filter]);

  useEffect(() => {
    getSiteLocations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails.role]);

  const columns = [
    {
      name: "No.",
      selector: ({ sl }) => sl,
      width: "60px",
    },
    {
      name: "User Name",
      cell: (row) => (
        <div className="d-flex my-2 align-items-center">
          <div>
            <img
              src={row.avatar}
              width={40}
              height={40}
              className="me-2 rounded-circle"
              style={{ objectFit: "cover" }}
              alt={`${row.name}`}
            />
          </div>
          <p className="m-0">{row?.name || "--"}</p>
        </div>
      ),
      width: "200px",
    },
    {
      name: "Email",
      cell: (row) => row.email,
      width: "200px",
    },
    {
      name: "User Type",
      cell: (row) => <div className="text-capitalize">{row.role}</div>,
      selector: (row) => row.role,
      // width: "100px",
      center: true,
    },
    {
      name: "Date Created",
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      center: true,
    },
    {
      name: "Last Login",
      cell: (row) => (
        <div>
          {row?.lastLogin ? (
            <div>
              {moment(row.lastLogin).format("DD/MM/YYYY")}
              <br />
              {moment(row.lastLogin).format("hh:mm:ss A")}
            </div>
          ) : (
            "--"
          )}
        </div>
      ),
      center: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <div>
          {row.isActive ? (
            <span className="badge text-bg-success">Active</span>
          ) : (
            <span className="badge text-bg-danger">Deactivated</span>
          )}
        </div>
      ),
      center: true,
      // width: '100px',
    },
    {
      name: "Action",
      width: "220px",
      center: true,
      cell: (row) => (
        <div>
          <Link to={`/users/details/${row._id}?role=${role || "all"}`} className="btn btn-success me-1">
            <FiEye title="View Profile" />
          </Link>
          <Link to={`/users/edit/${row._id}?role=${role || "all"}`} className="btn btn-info me-1">
            <FiEdit title="Edit Profile" />
          </Link>
          {userDetails?.role === userRole.superAdmin && (
            <>
              {row.isActive ? (
                <button className="btn btn-warning  me-1" onClick={() => onConfirmActiveDeactiveUser(row)}>
                  <FiUserX title="Inactive Account" />
                </button>
              ) : (
                <button className="btn btn-success  me-1" onClick={() => onConfirmActiveDeactiveUser(row)}>
                  <FiUserCheck title="Active Account" />
                </button>
              )}
            </>
          )}
          <button className="btn btn-danger" onClick={() => deleteUser(row._id)}>
            <FiTrash title="Delete" />
          </button>
        </div>
      ),
    },
  ];

  const getHeader = (role) => {
    switch (role) {
      case "installer":
        return {
          title: "All Installers",
          btnText: "Add Installer",
          btnLink: "/users/add-public-user",
        };
      case "admin":
        return {
          title: "All Admins",
          btnText: "Add Admin",
          btnLink: "/users/add-public-user",
        };
      case "user":
        return {
          title: "All Site Users",
          btnText: "Add Site User",
          btnLink: "/users/add-public-user",
        };
      case "public":
        return {
          title: "All Public Users",
          btnText: "Add User",
          btnLink: "/users/add-public-user",
        };
      default:
        return {
          title: "All Users",
          btnText: "Add User",
          btnLink: "/users/add-public-user",
        };
    }
  };

  return (
    <div className="card p-3 mb-3">
      <div className="row">
        <div className="col-md-6">
          <h4>{getHeader(role).title}</h4>
        </div>
        <div className="col-md-6 d-flex justify-content-end">
          <Link
            className="btn btn-info"
            to={{
              pathname: `/users/create`,
              ...(role ? { search: `role=${role}` } : {}),
            }}
          >
            {getHeader(role).btnText}
          </Link>
        </div>
      </div>
      <div className="filter mt-3">
        <Form layout="vertical" onValuesChange={(value) => setFilter((prev) => ({ ...prev, ...value }))}>
          <Row lg={4} md={2} sm={1}>
            {!role && (
              <Col>
                <Form.Item name="role">
                  <Select allowClear={true} className="w-100" placeholder="All User Types" options={userRoleOptions} />
                </Form.Item>
              </Col>
            )}
            <Col>
              <Form.Item name="isActive">
                <Select
                  allowClear={true}
                  className="w-100"
                  placeholder="All User Status"
                  options={[
                    { label: "Active", value: true },
                    { label: "Deactivated", value: false },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="site">
                <Select
                  allowClear={true}
                  className="w-100"
                  placeholder="All Sites"
                  options={siteLocations?.map((site) => ({ label: site.name, value: site._id }))}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <Spin spinning={isLoading}>
        <DataTable
          columns={columns}
          data={users}
          pagination
          paginationServer
          striped={!isDark}
          noDataComponent={isLoading ? "Loading" : "No record found."}
          theme={isDark ? "dark" : "light "}
          className="mt-3"
          paginationTotalRows={total}
          paginationPerPage={limit}
          onChangePage={(data) => {
            setPage(data);
          }}
          onChangeRowsPerPage={(data) => {
            setLimit(data);
            setPage(1);
          }}
          paginationRowsPerPageOptions={[10, 20, 50]}
        />
      </Spin>
    </div>
  );
};

export default UserList;
