import React, { useContext } from "react";
import Chart from "react-apexcharts";
import { ThemeContext } from "../../App.js";

const BarChart = ({
  showToolbar = false,
  title,
  name,
  data,
  yAxisMax,
  yAxisSuffix,
  xAxisName,
  xTitleClassName,
  colors,
  tools,
}) => {
  let { isDark } = useContext(ThemeContext);
  return (
    <div>
      <Chart
        options={{
          chart: {
            toolbar: {
              show: showToolbar,
              tools: {
                reset: false,
                download: false,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                ...tools,
              },
            },
            type: "bar",
            height: 350,
            zoom: {
              enabled: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          stroke: {
            curve: "straight",
          },
          title: {
            text: title,
            align: "center",
          },
          plotOptions: {
            bar: {
              distributed: colors?.length > 1,
            },
          },
          colors: colors,
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },

          yaxis: {
            title: {
              text: name,
              style: {
                fontSize: "14px",
              },
            },
            labels: {
              formatter: function (val) {
                return parseFloat(val).toFixed(0);
              },
            },
            max: yAxisMax,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return `${parseFloat(val).toFixed(2)}${yAxisSuffix ? yAxisSuffix : ""}`;
              },
            },
          },

          theme: isDark
            ? {
                mode: "dark",
                palette: "palette1",
                monochrome: {
                  enabled: false,
                  color: "green",
                  shadeTo: "light",
                  shadeIntensity: 0.65,
                },
              }
            : {},
        }}
        series={[
          {
            name: name,
            data: data,
          },
        ]}
        type="bar"
        height={350}
      />
      <p
        style={{
          fontSize: "14px",
          fontWeight: "bold",
          textAlign: "center",
        }}
        className={xTitleClassName}
      >
        {xAxisName}
      </p>
    </div>
  );
};

export default BarChart;
