import React, { useState, useEffect, useContext } from "react";
import { FiTrash, FiEdit } from "react-icons/fi";
import DataTable from "react-data-table-component";
import EditETariffModal from "../Modals/EditETariffModal";
import { useSelector } from "react-redux";
import { ThemeContext } from "../../App.js";
import { api, endpoint } from "../../api";
import { notification, Popconfirm } from "antd";

export default function ElectricityTariffTable({
  data,
  getElectricityTariff,
  setPage,
  setLimit,
  total,
  limit,
  listLoading,
}) {
  let { isDark } = useContext(ThemeContext);

  const [isLoading, setIsLoading] = useState(false);
  const userDetails = useSelector((state) => state.user.userDetails);
  const [colShow, setColShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [ETariffToEdit, setETariffToEdit] = useState({});
  const columns = [
    {
      name: "No.",
      cell: ({ sl }) => sl,
      width: "60px",
      center: true,
    },
    {
      name: "Category",
      selector: (row) => row.name,
      width: "100px",
    },
    {
      name: "Description",
      cell: (row) => (
        <div
          className="text-warp p-1 description"
          dangerouslySetInnerHTML={{ __html: row?.description }}
        ></div>
      ),
      selector: (row) => row?.description,
      grow: 3,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          {(() => {
            switch (userDetails.role) {
              case "superAdmin":
                return (
                  <div className="actions">
                    <button
                      className="btn btn-info me-1"
                      onClick={() => editETariff(row)}
                    >
                      <FiEdit />
                    </button>
                    <Popconfirm
                      title="Are you sure?"
                      okButtonProps={{
                        className: "btn btn-danger",
                        loading: isLoading,
                      }}
                      onConfirm={() => {
                        deleteETariff(row._id);
                      }}
                    >
                      <button className="btn btn-danger">
                        <FiTrash />
                      </button>
                    </Popconfirm>
                  </div>
                );
              default:
                return;
            }
          })()}
        </div>
      ),
      center: true,
      omit: colShow,
    },
  ];
  const editETariff = (etData) => {
    setETariffToEdit(etData);
    setModalShow(true);
  };
  const deleteETariff = async (etId) => {
    setIsLoading(true);
    try {
      const { message } = await api.delete(
        `${endpoint.delete_electricity_tariff}/${etId}`
      );
      notification.success({ message, placement: "bottomLeft" });
      getElectricityTariff();
    } catch (error) {
      notification.warning({
        message: "Something went wrong.",
        placement: "bottomLeft",
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (userDetails?.role === "superAdmin") {
    } else {
      setColShow(true);
    }
  }, [userDetails]);

  return (
    <div>
      <h5 className="mb-3">Tariff Categories</h5>
      <DataTable
        columns={columns}
        data={data}
        pagination
        paginationServer
        striped={!isDark}
        noDataComponent={listLoading ? "Loading" : "No record found."}
        theme={isDark ? "dark" : "light "}
        paginationTotalRows={total}
        paginationPerPage={limit}
        onChangePage={(data) => {
          setPage(data);
        }}
        onChangeRowsPerPage={(data) => {
          setLimit(data);
          setPage(1);
        }}
        paginationRowsPerPageOptions={[10, 20, 50]}
      />
      <EditETariffModal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          setETariffToEdit({});
        }}
        ETariffToEdit={ETariffToEdit}
        setModalShow={setModalShow}
        getElectricityTariff={getElectricityTariff}
      />
    </div>
  );
}
