import React, { useState, useEffect } from "react";
import ElectricityTariffTable from "../../components/Settings/ElectricityTariffTable";
import { useSelector } from "react-redux";
import { userRole } from "../../constant";
import { CreateTariffCategory } from "../../components/Settings/CreateTariffCategory";
import { api, endpoint } from "../../api";
import { Spin } from "antd";

const ElectricityTariff = () => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [eTariff, setETariff] = useState([]);
  const [listLoading, setListLoading] = useState(false);
  const userDetails = useSelector((state) => state.user.userDetails);

  const getElectricityTariffList = async () => {
    setListLoading(true);
    try {
      let { results, count } = await api.get(endpoint.get_electricity_tariff, {
        params: { limit, page, sortBy: "createdAt", orderBy: "DESC" },
      });

      results = results.map((item, idx) => {
        item.sl = (page - 1) * limit + idx + 1;
        return item;
      });
      setETariff(results);
      setTotal(count || 0);
    } catch (error) {}
    setListLoading(false);
  };
  console.log({ total, limit, page });
  useEffect(() => {
    document.title = "SEDA - Electricity Tariff";
    getElectricityTariffList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page]);

  return (
    <div className="card p-3 mb-3">
      <h4>Electricity Tariff</h4>
      <div className="row mt-4">
        {userDetails.role === userRole.superAdmin ? (
          <CreateTariffCategory getElectricityTariffList={getElectricityTariffList} />
        ) : (
          ""
        )}
        <div className={`col-md-${userDetails.role === userRole.superAdmin ? "8" : "12"}`}>
          <Spin spinning={listLoading}>
            <ElectricityTariffTable
              listLoading={listLoading}
              data={eTariff}
              getElectricityTariff={getElectricityTariffList}
              limit={limit}
              total={total}
              setPage={setPage}
              setLimit={setLimit}
            />
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default ElectricityTariff;
