import { notification, Spin } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { api, endpoint } from "../../api";
import { setUserDetails } from "../../redux/userSlice";

const EditProfile = () => {
  const [IsLoading, setIsLoading] = useState(false);
  const [updateProfileData, setUpdateProfileData] = useState({
    name: "",
    email: "",
    phone: "",
    fax: "",
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  //   const
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.user?.userDetails);
  const { name, email, phone, fax } = updateProfileData;

  useEffect(() => {
    if (userDetails) {
      setUpdateProfileData({
        name: userDetails.name,
        email: userDetails.email,
        phone: userDetails.phone,
        fax: userDetails.fax,
      });
    }
  }, [userDetails]);

  const handleFileUpload = (file) => {
    setImageUrl(URL.createObjectURL(file));
    let form = new FormData();
    form.append("avatar", file);
    setSelectedImage(form);
  };

  const onInputChange = (e) => {
    setUpdateProfileData({
      ...updateProfileData,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (selectedImage) {
        await api.put(endpoint.update_user_avater, selectedImage);
      }
      const user = await api.put(
        endpoint.update_user_details,
        updateProfileData
      );
      dispatch(setUserDetails(user));
      navigate("/profile");
      notification.info({
        message: "Profile Updated Successfully.",
        placement: "bottomLeft",
      });
    } catch (error) {
      notification.warning({
        message: "Something went wrong.",
        placement: "bottomLeft",
      });
    }
    setSelectedImage(null);
    setImageUrl(null);
    setIsLoading(false);

    // const response = await axios
    //   .put(`${process.env.REACT_APP_API_URL}/user/me`, UpdateProfileData, {
    //     withCredentials: true,
    //   })
    //   .catch(function (error) {
    //     if (error.response) {
    //       console.log(error.response.data);
    //       console.log(error.response.status);
    //       if (error.response.status === 400 || 500) {
    //         console.log(error);
    //       }
    //       console.log(error.response.headers);
    //     }
    //   });
    // const data = response.data;
    // if (data) {
    //   if (selectedImage === null) {
    //     dispatch(setUserDetails(response.data));
    //     setIsLoading(false);
    //     setSuccessMessage("Profile Updated Successfully");
    //     setTimeout(() => {
    //       setSuccessMessage();
    //       navigate("/profile");
    //     }, 3000);
    //   } else {
    //     const addImageResponse = await axios.put(
    //       `${process.env.REACT_APP_API_URL}/user/me/avatarUpload`,
    //       selectedImage,
    //       { withCredentials: true }
    //     );
    //     if (addImageResponse) {
    //       dispatch(setUserDetails(addImageResponse.data));
    //       setIsLoading(false);
    //       setSuccessMessage("Profile Updated Successfully");
    //       setTimeout(() => {
    //         setSuccessMessage();
    //         navigate("/profile");
    //       }, 3000);
    //     }
    //   }
    // }
  };
  useEffect(() => {
    document.title = "SEDA - Profile";
  }, []);
  return (
    <Spin spinning={IsLoading}>
      <div className="profile">
        <div className="container-fluid">
          <div className="row my-5">
            <div className="col-md-2"></div>
            <div className="col-md-10">
              <div className="card p-3 mb-3">
                <h4 className="mb-4">Profile</h4>
                <form onSubmit={submitHandler}>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={name}
                          onChange={onInputChange}
                          id="name"
                          placeholder="Enter your name"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Email address
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          value={email}
                          onChange={onInputChange}
                          id="email"
                          placeholder="Enter your email"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="phone" className="form-label">
                          Phone Number
                        </label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon1">
                            +6
                          </span>
                          <input
                            type="number"
                            name="phone"
                            value={phone}
                            onChange={onInputChange}
                            className="form-control"
                            id="phone"
                            placeholder="Enter phone number"
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        {/* <label htmlFor="fax" className="form-label">
                          Fax Number
                        </label> */}
                        <div className="input-group">
                          {/* <span className="input-group-text" id="basic-addon1">
                            +6
                          </span> */}
                          {/* <input
                            type="number"
                            name="fax"
                            value={fax}
                            onChange={onInputChange}
                            className="form-control"
                            id="fax"
                            placeholder="Enter fax number"
                          /> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-5">
                      <div className="mb-3">
                        <label htmlFor="avatar" className="form-label d-block">
                          Profile Photo
                        </label>

                        {imageUrl && selectedImage ? (
                          <div mt={2} textAlign="center">
                            <div>Image Preview:</div>
                            <img
                              src={imageUrl}
                              alt={selectedImage.name}
                              className="avater_profile"
                            />
                          </div>
                        ) : (
                          <>
                            <input
                              className="form-control"
                              accept="image/*"
                              type="file"
                              id="select-image"
                              style={{ display: "none" }}
                              onChange={(e) =>
                                handleFileUpload(e.target.files[0])
                              }
                            />
                            <label htmlFor="select-image">
                              <img
                                src={
                                  userDetails?.avatar || "/images/avatar.png"
                                }
                                alt=""
                                className="avater_profile"
                              />
                            </label>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="float-end">
                        <button type="submit" className="btn btn-success me-1">
                          Update
                        </button>
                        <Link to="/profile" className="btn btn-secondary">
                          Cancel
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default EditProfile;
